<header class="tw-container" [class.shop]="isHome$ | async">
    <div class="tw-h-full">
        <a class="logo" routerLink="/">
            <img src="assets/images/logo-66cc33.svg" />
        </a>

        <a class="contact" href="tel:+48606200002">
            <app-icon icon="phone" [size]="24" class="tw-mr-[0.875rem]" />
            +48 606 200 002
        </a>

        <div class="menu">
            @if (isHome$ | async) {
                <div class="tw-flex tw-items-center tw-gap-x-9" @ShowHide>
                    <app-nav-shop />
                    <app-settings-dropdowns />
                </div>
            } @else {
                <div @ShowHide class="tw-flex tw-items-center tw-gap-x-9">
                    <app-nav />
                    <app-auth />
                    <app-settings-dropdowns />
                </div>
            }
        </div>

        <div class="menu-mobile">
            <app-settings-dropdowns />
            <button class="tw-ml-3" type="button" (click)="onMobileClick()">
                <app-icon [size]="20" icon="hamburger" />
            </button>
        </div>
    </div>
</header>
