import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { LanguageService } from '@app/i18n';
import { environment } from '@env/environment';

import { ShowHide } from '../../animations/show-hide.animation';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [ShowHide],
})
export class HeaderComponent {
    private readonly languageService = inject(LanguageService);
    private readonly router = inject(Router);

    readonly isHome$ = this.router.events.pipe(
        startWith(this.router),
        filter((event) => event instanceof NavigationEnd),
        map((data) => data.url),
        startWith(this.router.url),
        map((url) => url !== '/' && url.startsWith('/(') == false),
    );
    readonly faqLink$ = this.languageService.language$.pipe(map((key) => environment.supportedLanguages.find((language) => language.key === key)?.faq));

    onMobileClick() {
        this.router.navigate([{ outlets: { mobile: 'menu' } }]);
    }
}
