import { CanMatchFn } from '@angular/router';

const canEnterV2: CanMatchFn = () => {
    return localStorage.getItem('design') === 'v2';
};

const canEnterV1: CanMatchFn = () => {
    return localStorage.getItem('design') !== 'v2';
};

export { canEnterV1, canEnterV2 };
