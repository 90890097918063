<div class="tw-flex tw-gap-x-4">
    <a href="#">
        <app-icon class="tw-block" [size]="42" icon="social-in" color="gray-600" />
    </a>
    <a href="#">
        <app-icon class="tw-block" [size]="42" icon="social-fb" color="gray-600" />
    </a>
    <a href="#">
        <app-icon class="tw-block" [size]="42" icon="social-yt" color="gray-600" />
    </a>
</div>
