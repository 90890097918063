import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { bufferCount, filter, fromEvent, map, tap } from 'rxjs';

const PASS = '1234';

@Injectable({
    providedIn: 'root',
})
export class DesignSwitcherService {
    private router = inject(Router);

    constructor() {
        fromEvent<KeyboardEvent>(document, 'keypress')
            .pipe(
                map((event) => event.key),
                bufferCount(PASS.length, 1),
                map((letters) => letters.join('').toLocaleLowerCase()),
                filter((text) => text === PASS),
                tap(() => {
                    if (localStorage.getItem('design') === 'v2') {
                        localStorage.setItem('design', 'v1');
                    } else {
                        localStorage.setItem('design', 'v2');
                    }

                    this.router.navigateByUrl(this.router.url, { onSameUrlNavigation: 'reload' });
                }),
            )
            .subscribe();
    }
}
