import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { accountCleanup } from '@app/store/account';

import { popupShell } from '../shared/shell-popup';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './components/reset-password-confirm/reset-password-confirm.component';
import { AccountActivateComponent } from './components/activate/activate.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RedirectComponent } from './components/redirect/redirect.component';

const routes: Routes = [
    popupShell('account', [
        {
            path: 'login',
            canDeactivate: [accountCleanup()],
            component: LoginComponent,
        },
        {
            path: 'logout',
            canDeactivate: [accountCleanup()],
            component: LogoutComponent,
        },
        {
            path: 'register',
            canDeactivate: [accountCleanup()],
            component: RegisterComponent,
        },
        {
            path: 'reset-password-request',
            canDeactivate: [accountCleanup()],
            component: ResetPasswordComponent,
        },
        {
            path: 'reset-password-request-confirm',
            canDeactivate: [accountCleanup()],
            component: ResetPasswordConfirmComponent,
        },
        {
            path: 'activate/:token',
            canDeactivate: [accountCleanup()],
            component: AccountActivateComponent,
        },
        {
            path: 'set-password/:token',
            canDeactivate: [accountCleanup()],
            component: SetPasswordComponent,
        },
    ]),
    {
        path: 'account/:id',
        component: RedirectComponent,
    },
    {
        path: 'account/:id/:token',
        component: RedirectComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountRoutingModule {}
