<div class="list">
    @for (option of options; track option.value) {
        <button type="button" (click)="onSelectClick(option.value)">
            @if (type === 'language') {
                <div class="flag" [ngStyle]="{ 'background-image': getFlagUrl(option.value) }"></div>
            }
            {{ option.name }}
        </button>
    }
</div>
