import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-account-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class RedirectComponent implements OnInit {
    readonly activatedRoute = inject(ActivatedRoute);
    readonly router = inject(Router);

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        const token = this.activatedRoute.snapshot.paramMap.get('token');
        switch (id) {
            case 'login':
                this.router.navigateByUrl('/(modal:account/login)');
                break;
            case 'register':
                this.router.navigateByUrl('/(modal:account/register)');
                break;
            case 'reset-password-request':
                this.router.navigateByUrl('/(modal:account/reset-password-request)');
                break;
            case 'reset-password':
                this.router.navigateByUrl(`/(modal:account/set-password/${token})`);
                break;
            default:
                this.router.navigateByUrl(`/`);
                break;
        }
    }
}
