import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Subscription, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { CurrencyService } from '@app/currency';
import { LanguageService } from '@app/i18n';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ShowSettingsService } from '@app/shared/services/show-settings.service';

@Component({
    selector: 'app-settings-dropdowns',
    styleUrls: ['./settings-dropdowns.component.scss'],
    templateUrl: './settings-dropdowns.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, DropdownComponent, CommonModule],
})
export class SettingsDropdownsComponent implements OnInit, OnDestroy {
    private readonly languageService = inject(LanguageService);
    private readonly currencyService = inject(CurrencyService);
    private readonly translateService = inject(TranslateService);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly settingsService = inject(ShowSettingsService);

    readonly showSettings$ = this.settingsService.showSettings$;
    readonly form = this.formBuilder.group({
        currency: this.formBuilder.control(this.currencyService.get()),
        language: this.formBuilder.control(this.languageService.get()),
    });

    readonly languages = environment.supportedLanguages.map((language) => ({
        name: this.translateService.instant(`language.select.${language.key}`),
        value: language.key,
    }));

    readonly currencies = environment.currency.available.map((currency) => ({
        name: currency,
        value: currency,
    }));

    private readonly subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(this.form.controls.language.valueChanges.pipe(tap((value) => this.languageService.set(value))).subscribe());
        this.subscriptions$.add(this.form.controls.currency.valueChanges.pipe(tap((value) => this.currencyService.set(value))).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onCurrencyChange(value: string): void {
        this.form.controls.currency.setValue(value);
    }

    onLanguageChange(value: string): void {
        this.form.controls.language.setValue(value);
    }
}
