import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { filter, Subscription, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { AccountFacade } from '@app/store/account';
import { ButtonComponent, ButtonLinkComponent, IconComponent, PopupComponent } from '@app/v2/shared/components';
import { OrderFacade } from '@app/store/order';
import { FormInputComponent, FormValidators } from '@app/v2/shared/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        FormInputComponent,
        ButtonComponent,
        ButtonLinkComponent,
        IconComponent,
        PopupComponent,
    ],
})
export class LoginComponent implements OnInit, OnDestroy {
    private readonly accountFacade = inject(AccountFacade);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly router = inject(Router);
    private readonly orderFacade = inject(OrderFacade);

    private readonly subscriptions$ = new Subscription();

    readonly spinner$ = this.accountFacade.saving$;

    readonly form = this.formBuilder.group({
        email: this.formBuilder.control<string>('', [FormValidators.Required, FormValidators.Email]),
        password: this.formBuilder.control<string>('', [FormValidators.Required, FormValidators.Text]),
    });

    ngOnInit(): void {
        this.subscriptions$.add(
            this.accountFacade.saving$
                .pipe(
                    filter((saving) => saving === false),
                    tap(() => this.form.enable()),
                )
                .subscribe(),
        );
        this.subscriptions$.add(
            this.accountFacade.logged$
                .pipe(
                    filter((logged) => logged === true),
                    tap(() => this.close()),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onSubmit() {
        const assignId = this.orderFacade.order?.id ?? null;
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.disable();
            const value = this.form.getRawValue();
            this.accountFacade.login(value.email, value.password, assignId);
        }
    }

    private close() {
        this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
    }
}
