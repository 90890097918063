import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/v2/shared/components';

@Component({
    selector: 'app-nav-social',
    templateUrl: './nav-social.component.html',
    styleUrls: ['./nav-social.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent],
})
export class NavSocialComponent {}
